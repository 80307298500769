





















































































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import DateTimeInput from '@/partials/forms/components/DateTime/DateTime.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import MoneyInput from '@/partials/forms/components/MoneyInput.vue'

@Component({
  directives: { mask },
  components: {
    MoneyInput,
    DateTimeInput,
    NotifyOnFailed,
    PasswordConfirmField,
    ValidationProvider,
    ValidationObserver
  }
})
export default class SubscriptionTypesForm extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) type!: 'create' | 'edit'
  @Prop({ type: String }) eventId!: 'create' | 'edit'
  @Prop() subscriptionType!: Record<string, any>

  done = true
  form: Record<string, any> = {
    type: '',
    price: 0,
    availableOn: moment().format('YYYY-MM-DD HH:mm:ss'),
    availableUp: moment().add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
    vacancies: '1',
    availability: 'ongoing',
    visibility: 'public',
    exclusivity: true,
    attachmentsRequired: false,
    confirm: '',
    eventId: '',
    errors: {
      type: '',
      price: '',
      availableOn: '',
      availableUp: '',
      vacancies: '',
      confirm: ''
    }
  }

  availabilities = [
    {
      prettyName: 'À venda',
      name: 'ongoing'
    },
    {
      prettyName: 'Encerrado',
      name: 'finished'
    }
  ]

  visibilities = [
    {
      prettyName: 'Sim',
      name: 'public'
    },
    {
      prettyName: 'Não',
      name: 'private'
    }
  ]

  exclusivities = [
    {
      prettyName: 'Apenas quites',
      name: 'true'
    },
    {
      prettyName: 'Todos',
      name: 'false'
    }
  ]

  attachmentsRequired = [
    {
      prettyName: 'Sim',
      name: 'true'
    },
    {
      prettyName: 'Não',
      name: 'false'
    }
  ]

  created () {
    this.form.eventId = this.eventId
    if (process.env.NODE_ENV === 'development' && this.type !== 'edit') {
      Object.assign(this.form, {
        type: 'Tipo Teste',
        price: 1234.99,
        vacancies: '50'
      })
    }

    if (this.type === 'edit') {
      Object.keys(this.subscriptionType).forEach((key: string) => {
        this.form[key] = key in this.form ? this.subscriptionType[key] : this.form[key]
      })
    }
  }

  submit () {
    this.done = false
    const verb = this.type === 'edit' ? 'put' : 'post'
    const route = this.type === 'edit' ? `/event/${this.eventId}/subscription/type/${this.subscriptionType.id}` : `/event/${this.eventId}/subscription/type`

    axios[verb](route, snakeCaseKeys(this.form))
      .then(() => {
        this.redirectOnSuccess(this.form.type)
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  redirectOnSuccess (entityName: string) {
    const successVerb = this.type === 'edit' ? 'editada' : 'cadastrada'

    const flashMessage = {
      message: `A inscrição ${entityName} foi ${successVerb} com sucesso.`,
      kind: 'success'
    }

    this.$router.push({ name: 'EventSubscriptionTypes', params: { id: this.$route.params.eventId } }, () => this.setFlashMessage(flashMessage))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  resetErrors () {
    this.form.errors = {
      type: '',
      price: '',
      availableOn: '',
      availableUp: '',
      vacancies: '',
      confirm: ''
    }
  }
}
